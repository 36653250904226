<template>
  <div>
    <div class="row">
      <!-- side left -->
      <div class="col-sm-3">
        <b-row>
          <b-col sm="12" cols="6">
            <RWCard title="Status" :bodyFit="true">
              <template v-slot:body>
                <div v-if="loading.sum">
                  <v-skeleton-loader
                    type="text@6"
                    class="mx-auto my-5"
                    width="200"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <div v-if="success.status">
                    <div
                      class="list-group list-group-flush"
                      v-for="(row, index) in xhr.status"
                      :key="index"
                      @click="change_status(row.name)"
                      style="cursor: pointer"
                    >
                      <a
                        class="
                          list-group-item list-group-item-action
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              :value="row.name"
                              v-model="current_status"
                            />
                            <span class="ml-2 text-capitalize">{{
                              row.name
                            }}</span>
                          </label>
                        </div>

                        <span class="badge badge-secondary badge-pill">{{
                          row.count
                        }}</span>
                      </a>
                    </div>
                  </div>

                  <div v-else>
                    <b-alert show variant="danger" class="m-4">{{
                      xhr.status.message
                    }}</b-alert>
                  </div>
                </div>
              </template>
            </RWCard>
          </b-col>
          <b-col sm="12" cols="6">
            <RWCard :title="title_due">
              <template v-slot:body>
                <p v-if="loading.due">
                  <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                </p>
                <div v-if="!loading.due && success.due">
                  <div @click="change_status('due')" style="cursor: pointer">
                    You have
                    <b class="font-weight-bold">{{ xhr.due.count }}</b>
                    invoice(s) currently unpaid with a total balance of
                    <b class="font-weight-bold">Rp{{ xhr.due.amount }}</b>
                  </div>
                </div>
                <b-alert v-else show variant="danger">{{
                  xhr.due.message
                }}</b-alert>
              </template>
            </RWCard>
          </b-col>
        </b-row>
      </div>

      <!-- side right -->
      <div class="col-sm-9">
        <RWCard :title="title_list">
          <template v-slot:body>
            <div v-if="loading.table">
              <b-skeleton-table
                :rows="pagination.limit"
                :column="3"
                :table-props="{ striped: true }"
              ></b-skeleton-table>
            </div>
            <div v-else>
              <div v-if="success.invoice">
                <!-- show table != small  -->
                <div v-if="xhr.invoices.length">
                  <b-skeleton-table
                    :rows="pagination.limit"
                    :columns="5"
                    :table-props="{ striped: true }"
                    v-if="loading.pagination"
                  ></b-skeleton-table>

                  <!-- else length -->
                  <div v-else>
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th class="text-center">ID</th>
                          <th class="d-none d-sm-table-cell text-center">
                            Inv Date
                          </th>
                          <th class="text-center">Jatuh Tempo</th>
                          <th class="text-center">Total</th>
                          <th class="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(inv, key) in xhr.invoices" :key="key">
                          <td class="text-center">{{ inv.id }}</td>
                          <td class="d-none d-sm-table-cell text-center">
                            {{ inv.date }}
                          </td>
                          <td class="text-center">{{ inv.duedate }}</td>
                          <td class="text-center">Rp{{ inv.total }}</td>
                          <td class="text-center">
                            <b-link
                              :to="{ path: 'invoice/' + inv.id }"
                              class="btn btn-outline-secondary btn-sm btn-block"
                              :class="mx_text_color(inv.status)"
                              >{{ inv.status }}</b-link
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <b-pagination
                      v-model="pagination.current"
                      :total-rows="pagination.allCount"
                      :per-page="pagination.limit"
                      align="right"
                      first-text="First"
                      @change="handlePagination"
                      prev-text="Prev"
                      next-text="Next"
                      last-text="Last"
                    ></b-pagination>
                  </div>
                </div>

                <div v-else>
                  <b-alert show variant="warning">Invoice not found</b-alert>
                </div>
              </div>
              <b-alert v-else show variant="danger">{{
                xhr.invoices.message
              }}</b-alert>
            </div>
          </template>
        </RWCard>
      </div>
    </div>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { invoiceMixin } from "@/common/mixins/invoice/invoice-mixin.js";
import {
  breadcrumb,
  ApiURL,
  httpCode,
  util,
  notif
} from "@/common/mixins/general.js";

export default {
  name: "InvoiceAll",
  mixins: [breadcrumb, ApiURL, invoiceMixin, httpCode, util, notif],
  components: { RWCard },
  data() {
    return {
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
        allCount: 0
      },
      current_status: "",
      xhr: {
        invoices: [],
        due: "",
        status: []
      },
      loading: {
        sum: true,
        due: true,
        table: true,
        pagination: false
      },
      success: {
        due: false,
        status: false,
        invoice: false
      }
    };
  },

  methods: {
    /**
     * -------------------------------------------------
     * get sum count invoice by status
     * -------------------------------------------------
     */
    get_sum() {
      this.xhr_invoice
        .get("sum")
        .then(({ data }) => {
          this.xhr.status = data.data.invoice;
          this.success.status = true;
        })
        .catch(({ response }) => {
          this.xhr.status = response.data;
        })
        .finally(() => {
          this.loading.sum = false;
        });
    },

    /**
     * -------------------------------------------------
     * get invoice due
     * -------------------------------------------------
     */
    get_due: function(query = false) {
      if (query) {
        query = this.mx_ObjectToQuery(query, "?");
      }
      this.xhr_invoice
        .get("due", query)
        .then(({ data }) => {
          this.xhr.due = data.data.due;

          // set jika di url ada get status=due
          if (query && this.current_status === "due") {
            this.xhr.invoices = data.data.due.record;
            this.pagination.allCount = data.data.due.count;

            this.loading.table = false;
            this.loading.pagination = false;
          }
          this.success.due = true;
        })
        .catch(({ response }) => {
          this.xhr.due = response.data;
        })
        .finally(() => {
          this.loading.due = false;
        });
    },

    /**
     * -------------------------------------------------
     * get invoice semuanya
     * -------------------------------------------------
     */
    get_invoice_all(QueryObject = "") {
      let query = "";
      if (QueryObject !== "") {
        query = this.mx_ObjectToQuery(QueryObject, "?");
      }
      this.xhr_invoice
        .get("all", query)
        .then(({ data }) => {
          this.xhr.invoices = data.data.invoice.record;
          this.pagination.allCount = data.data.invoice.count;
          this.success.invoice = true;
        })
        .catch(({ response }) => {
          // handle jika ketika next page ada error
          this.xhr.invoices = response.data;
          let current = this.pagination.current;
          this.pagination.current = current !== 1 ? current - 1 : 1;
        })
        .finally(() => {
          this.loading.table = false;
          this.loading.pagination = false;
        });
    },

    /**
     * -------------------------------------------------
     * klik baris table
     * -------------------------------------------------
     */
    row_click(item) {
      this.$router.push({ name: "invoiceRow", params: { id: item[0]["id"] } });
    },

    /**
     * ------------------------------------------------
     * get status paid/unpaid/cancelled/refunded
     * ------------------------------------------------
     */
    change_status(status) {
      this.loading.table = true;
      this.pagination.offset = 0;
      this.pagination.current = 1;
      let query = {
        limit: this.pagination.limit,
        offset: this.pagination.offset
      };

      if (status === "due") {
        // status additional invoice due date
        this.current_status = status;
        this.get_due(query);
      } else {
        if (this.current_status === status) {
          // toggle status
          this.current_status = "";
        } else {
          this.current_status = status;
          query = Object.assign(query, { status: status });
        }
        this.get_invoice_all(query);
      }
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev
     * ------------------------------------------------
     */
    handlePagination: function(value) {
      this.loading.pagination = true;
      this.pagination.offset = (value - 1) * this.pagination.limit;

      let query = new Object();
      query.limit = this.pagination.limit;
      query.offset = this.pagination.offset;

      if (this.current_status === "due") {
        // get invoice due date
        this.get_due(query);
      } else {
        if (this.current_status !== "") {
          query.status = this.current_status;
        }
        this.get_invoice_all(query);
      }
    }
  },
  computed: {
    title_due: function() {
      if (this.loading.due) {
        if (this.success.due) {
          return `${this.xhr.due.count} Invoices Due`;
        }
      }
      return "Invoices Due";
    },
    title_list: function() {
      return (
        "My Invoices " +
        this.current_status.charAt(0).toUpperCase() +
        this.current_status.slice(1)
      );
    }
  },
  mounted() {
    this.get_sum();
    this.get_due();
    this.set_breadcrumb([{ title: "Invoice", route: "invoice" }]);

    /**
     * --------------------------------------------
     * handle jika ada redirect dari other page
     * yang mengharuskan pake status
     * --------------------------------------------
     */
    let query = this.$route.query;
    const status = ["paid", "unpaid"]; // status allowed from other page
    if (typeof query.status !== "undefined" && status.includes(query.status)) {
      this.change_status(query.status);
    } else {
      let query = {
        limit: this.pagination.limit,
        offset: this.pagination.offset
      };
      this.get_invoice_all(query);
    }
  }
};
</script>

<style scoped>
.list-group-flush > .list-group-item {
  cursor: pointer;
  border-width: 0 0 1px;
}
</style>
